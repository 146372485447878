import React, {useEffect, useState, useCallback} from 'react'
import { List, Datagrid, TextField, SelectInput, ReferenceField, EditButton, DeleteButton, Filter, useDataProvider, FunctionField } from 'react-admin' 

const Filters = props => {
  const [companies, setCompanies] = useState([])
  const [buildTypes, setBuildTypes] = useState([])
  const [models, setModels] = useState([])
  const [modelGroups, setModelGroups] = useState([])
  const dataProvider = useDataProvider()

  const fetchItems = useCallback(async(source) =>{
    const response =  await dataProvider.getList(source, {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'id',
        order: 'ASC'
      }
    })
    const data = response.data.map(elem => {elem.id = elem.id ? elem.id : elem['@id'].match(/\d+/)[0]; return elem})
    return data
  }, [dataProvider])

  useEffect(() => {
    const fetchFilters = async() => {
      const companiesList = await fetchItems('companies')
      setCompanies(companiesList.map(e => ({name: e.name, id: e.id})))
      const buildTypesList = await fetchItems('build_types')
      setBuildTypes(buildTypesList.map(e => ({name: e.name, id: e.id})))
      const modelsList = await fetchItems('models')
      setModels(modelsList.map(e => ({name: e.name, id: e.id})))
      const modelGroupsList = await fetchItems('model_groups')
      setModelGroups(modelGroupsList.map(e => ({name: e.name, id: e.id})))
    }
    fetchFilters()
  }, [])
  return (
    <Filter {...props} >
      <SelectInput alwaysOn source="company.id" label="Company" choices={companies} />
      {/*<SelectInput alwaysOn source="buildType.id" label="BuildType" choices={buildTypes} />*/}
      <SelectInput alwaysOn source="model.id" label="Model" choices={models} />
      <SelectInput alwaysOn source="modelGroup.id" label="Model group" choices={modelGroups} />
    </Filter>
    )
}

export const ListBuildConfigs = props => (
  <List {...props} exporter={false} filters={<Filters />}>
    <Datagrid rowClick={null}>
      <TextField source="originId" label="ID" />
      <ReferenceField source="company['@id']" label="Company" reference='companies'>
        <TextField source='name' />
      </ReferenceField>
      {/*<TextField source="buildType.name" label="Build type" />*/}
      <TextField source="model.name" label="Model" />
      <TextField source="modelGroup.name" label="Model group" />
      <TextField source="parameter.name" label="parameter" />
      <TextField source="selectedOption.name" label="Selected option" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
)

import React, { cloneElement, useEffect } from 'react';
import {Edit, useEditController} from 'react-admin';
import { transfromCompanies } from '../utils/transformCompanies';
import {CompanyForm} from './_Form';

const CustomEdit = props => {
  const {
      basePath, // deduced from the location, useful for action buttons
      record, // record fetched via dataProvider.getOne() based on the id from the location
      redirect, // the default redirection route. Defaults to 'list'
      resource, // the resource name, deduced from the location. e.g. 'posts'
      save, // the update callback, to be passed to the underlying form as submit handler
      saving, // boolean that becomes true when the dataProvider is called to update the record
      version, // integer used by the refresh feature
  } = useEditController(props)
  useEffect(() => {
    if (record) {
      const {feePools} = record
      feePools.forEach(pool => {
        const algo = pool.ourPool.algo
        if (record[`feePools(${algo})`]) {
          record[`feePools(${algo})`].push(pool)
        } else {
          record[`feePools(${algo})`] = [pool]
        }
      })
    }
  }, [record])
  return (
      <div>
          {cloneElement(props.children, {
              basePath,
              record,
              redirect,
              resource,
              save,
              saving,
              version,
          })}
      </div>
  )

} 

export const EditCompany = (props) => (
  <CustomEdit {...props} undoable={false} title={'Edit'} transform={transfromCompanies}>
    <CompanyForm {...props}/>
  </CustomEdit>
);



import React, { useState } from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  Toolbar,
  useRedirect,
  useNotify,
} from 'react-admin'
import { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import ContentSave from '@material-ui/icons/Save'

const CustomToolbar = (props) => {
  const [disabled, setDisabled] = useState(false)
  const redirect = useRedirect()
  const notify = useNotify()
  const { record, selectedOption } = props
  const { parameter, company, model, modelGroup } = record
  const handleSave = async () => {
    setDisabled(true)
    const body = {
      parameter: parameter['@id'],
      company: company['@id'],
      model: model ? model['@id'] : undefined,
      modelGroup: modelGroup ? modelGroup['@id'] : undefined,
      selectedOption: `/api/config_param_options/${selectedOption}`,
      overrideModels: true,
    }
    const response = await fetch(
      `${process.env.REACT_APP_ENTRYPOINT}/api/param_values/set`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage['access_token']}`,
          'Content-Type': 'application/ld+json',
        },
        body: JSON.stringify(body),
      }
    )
    if (response.ok) {
      notify('Element saved')
      redirect('/param_values')
    }
  }
  return (
    <Toolbar {...props}>
      <Button
        disabled={disabled || !selectedOption}
        variant="contained"
        color="primary"
        onClick={() => handleSave()}
      >
        <ContentSave style={{ marginRight: 10 }} />
        Save
      </Button>
    </Toolbar>
  )
}

export const BuildConfigForm = (props) => {
  const [options, setOptions] = useState([])
  const [currentOption, setCurrentOption] = useState(null)
  const { record } = props

  useEffect(() => {
    const fetchOptions = async () => {
      if (record) {
        const parameter = record.parameter?.id
        const model = record.model?.id
        let modelGroup
        if (!model) {
          modelGroup = record.modelGroup?.id
        }
        const url = `${
          process.env.REACT_APP_ENTRYPOINT
        }/api/config_param_options?parameter.id=${parameter}${
          model
            ? `&models.id=${model}`
            : modelGroup
            ? `&modelGroups.id=${modelGroup}`
            : ''
        }`
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${localStorage['access_token']}`,
          },
        })
        const data = await response.json()
        setOptions(data['hydra:member'])
      }
    }
    fetchOptions()
  }, [record])

  const format = (r) => (r && r['@id'] ? r['@id'] : r)
  return (
    <SimpleForm
      {...props}
      toolbar={<CustomToolbar selectedOption={currentOption} />}
    >
      <ReferenceInput
        source="company"
        format={format}
        label="Company"
        reference="companies"
      >
        <SelectInput disabled optionName="name" />
      </ReferenceInput>
      <ReferenceInput
        source="parameter"
        format={format}
        label="Parameter"
        reference="config_param_definitions"
      >
        <SelectInput disabled optionName="name" />
      </ReferenceInput>
      <SelectInput
        source="selectedOption"
        format={(v) => v.id}
        choices={options}
        optionText="name"
        inputProps={{ onChange: (e) => setCurrentOption(e.target.value) }}
      />
      {record.model && (
        <ReferenceInput
          source="model"
          format={format}
          label="Model"
          reference="companies"
        >
          <SelectInput disabled optionName="name" />
        </ReferenceInput>
      )}
      {record.modelGroup && (
        <ReferenceInput
          source="modelGroup"
          format={format}
          label="Model group"
          reference="companies"
        >
          <SelectInput disabled optionName="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  )
}
